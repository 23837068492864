import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

const SocialBar = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 2em;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    * {
      font-size: 0.8em;
    }
  }
  @media only screen and (min-width: 1000px) {
    /* For everything bigger than 768px */
    * {
      font-size: 0.95em;
    }
  }
`

const ExtLink = styled.a`
  text-decoration: none;
  color: inherit;
  box-shadow: none;
`

export default () => {
  return (
    <SocialBar>
      <ExtLink href="https://github.com/Brgraul" target="_blank">
        <FontAwesomeIcon icon={faGithub} />
      </ExtLink>
      <ExtLink href="https://www.linkedin.com/in/brgraul/" target="_blank">
        <FontAwesomeIcon icon={faLinkedinIn} />
      </ExtLink>
      <ExtLink href="https://twitter.com/brgraul" target="_blank">
        <FontAwesomeIcon icon={faTwitter} />
      </ExtLink>
      <ExtLink href="mailto:hello@raulberganza.tech" target="_blank">
        <FontAwesomeIcon icon={faEnvelope} />
      </ExtLink>
    </SocialBar>
  )
}
